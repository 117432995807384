import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import language_config from "../../../language-config"
import Backend from "i18next-xhr-backend"

const fallbackLng = ["pl"]
export const availableLanguages = ["pl", "ru", "lv", "lt", "en", "de"]

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng,
    debug: false,
    ns: ["translation"],
    defaultNS: "translation",
    whitelist: availableLanguages,
    resources: {
      backend: {
        loadPath: "locales/{{lng}}/{{ns}}.json",
      },
    },

    interpolation: {
      escapeValue: false,
    },

    react: {
      wait: true,
      useSuspense: false,
    },
  })

i18n.languages = Object.keys(language_config)

export const localize = (string, values) => {
  return i18n.t(string, { defaultValue: string, ...values })
}

export default i18n
