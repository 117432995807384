import React from "react"
import { Link } from "gatsby"
import language_config from "../../../language-config"
import styled from "styled-components"
import { StyledButton as Button } from "./Button"
import { GlobalColors } from "../../assets/styles/GlobalVariables"
import arrowIcon from "../../assets/images/icons/link_arrow.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const StyledButton = styled(Button)`
  display: inline-block;
  text-decoration: none;
  font-size: 1.8rem;
  font-family: Teko;
  padding: 8px 20px;
  
  ${({ icon }) => icon ? "padding-right: 40px" : null}
`

const StyledLink = styled(Link)`
  display: inline-block ;
  color: ${GlobalColors.primary};
  font-family: Teko;
  font-weight: 600;
  font-size: 1.8rem;
  text-transform: uppercase;
  line-height: 1.8rem;
  position: relative;
  text-decoration: none;

  ::after {
    content: url(${arrowIcon});
    position: relative;
    display: inline-block;
    margin-left: 10px;
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
    top: 1px;
  }

  :hover::after {
    transform: translateX(10px);
  }
`

const LocalizedLink = ({
                         to,
                         language,
                         isButton,
                         isStyledLink,
                         children,
                         className,
                         isRevert,
                         ...props
                       }) => {
  const { is_default, path } = language_config[language]
  const path_to = is_default ? to : `/${path}${to}`
  const isAnchorLink = to.includes("#")
  if (isButton) {
    return (
      <StyledButton className={className}
                    as={Link}
                    to={path_to}
                    {...props}>
        {children}
      </StyledButton>
    )
  }
  if (isStyledLink) {
    return (
      <StyledLink className={className} {...props} to={path_to}>
        {children}
      </StyledLink>
    )
  }
  if (isAnchorLink) {
    return (
      <AnchorLink className={className} stripHash {...props} to={path_to}>
        {children}
      </AnchorLink>
    )
  }
  return (
    <Link className={className} {...props} to={path_to}>
      {children}
    </Link>
  )
}

export default LocalizedLink
