import React, { Component } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import language_config from "../../../../language-config"
import Dropdown from "../../Atoms/Dropdown"
import arrowDownIcon from "../../../assets/images/icons/Arrow Down.svg"
import { availableLanguages } from "../../Atoms/Language"
import pl from "../../../assets/images/icons/flags/poland.svg"
import ru from "../../../assets/images/icons/flags/russia.svg"
import lt from "../../../assets/images/icons/flags/lithuania.svg"
import lv from "../../../assets/images/icons/flags/latvia.svg"
import en from "../../../assets/images/icons/flags/england.svg"
import de from "../../../assets/images/icons/flags/deutsch.svg"

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  height: 100px;
  align-items: center;

  @media screen and (max-width: 1100px) {
    position: absolute;
    top: -60px;
    right: 60px;
    height: auto;

    .dropdown {
      top: 52px !important;
      right: 0 !important;
      left: unset !important;
      padding: 22px;
      min-width: 170px;
      ::before {
        right: 50px;
      }
    }
  }

  .dropdown {
    position: absolute;
    top: 102px;
    left: -30px;
    display: ${({ dropdownIsHidden }) => (dropdownIsHidden ? "none" : "block")};
    animation: dropdown 0.3s ease-in-out;
  }
`

const StyledLanguageButton = styled.li`
  font-size: ${({ lang }) => (lang ? ".8em !important" : "1em !important")};
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledLanguageSwitcher = styled.span`
  display: inline-block;
  text-transform: uppercase;
  padding-right: 25px;
  font-family: Teko;
  margin-right: 18px;
  cursor: pointer;
  background-image: url(${arrowDownIcon});
  background-repeat: no-repeat;
  background-position: right top 2px;
  background-size: 60%;
`

const StyledFlagIcon = styled.img`
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
`

const languages = Object.keys(language_config)

class LanguageSwitcher extends Component {
  state = {
    dropdownIsHidden: true,
  }

  setDefaultLanguage() {
    const browserLanguage = window.navigator.language || null
    const localStorageLanguage = localStorage ? localStorage.getItem("language") : null
    const path = `${this.props.location.pathname.replace(/^\/..\//, "/")}`

    if (localStorageLanguage && localStorageLanguage !== this.props.language) {
      navigate(localStorageLanguage ? `/${localStorageLanguage}${path}` : `${path}`)
    } else if (!localStorageLanguage && browserLanguage !== this.props.language && availableLanguages.includes(browserLanguage)) {
      navigate(browserLanguage ? `/${browserLanguage}${path}` : `${path}`)
      localStorage.setItem("language", this.props.language)
    }
  }

  componentDidMount() {
    if (typeof window === "undefined") {
      return
    }
    window.addEventListener("click", e => this.hideDropdown(e))
    this.setDefaultLanguage()
  }

  componentWillUnmount() {
    window.removeEventListener("click", e => this.hideDropdown(e))
  }

  handleSelect = language => {
    const selected_language = language
    const currentUrl = this.props.location.pathname.replace(/^\/..\//, "/")
    const path = language_config[selected_language].is_default
      ? currentUrl
      : `/${selected_language}${currentUrl}`

    this.toggleDropdown()
    localStorage.setItem("language", language)
    navigate(path ? path : "/")
  }

  toggleDropdown = () => {
    this.setState(state => ({ dropdownIsHidden: !state.dropdownIsHidden }))
  }

  hideDropdown = e => {
    if (
      typeof e.target.className === "string" &&
      e.target.className.includes("LanguageSwitcher")
    ) {
      return
    }
    this.setState({ dropdownIsHidden: true })
  }

  render() {
    const { language } = this.props
    const flags = {
      pl, ru, lt, lv, en, de
    }
    return (
      <StyledContainer dropdownIsHidden={this.state.dropdownIsHidden}>
        <StyledLanguageSwitcher onClick={this.toggleDropdown}>
          {language}
        </StyledLanguageSwitcher>
        <Dropdown className="dropdown">
          <ul>
            {languages.map(lang => (
              <StyledLanguageButton
                key={lang}
                role="button"
                lang={lang}
                onClick={() => this.handleSelect(lang)}
              >
                <StyledFlagIcon src={flags[lang]} alt={"wybór języka" + language}/>{language_config[lang].display_name}
              </StyledLanguageButton>
            ))}
          </ul>
        </Dropdown>
      </StyledContainer>
    )
  }
}

export default LanguageSwitcher
