import React from "react"
import styled, { css } from "styled-components"
import { GlobalColors } from "../../assets/styles/GlobalVariables"

const StyledLogo = styled.svg`
  ${({ withAnimation }) =>
    withAnimation
      ? css`
          .m {
            stroke-dasharray: 90;
            stroke-dashoffset: 0;
            animation: mAnimation 1s ease-in-out;
          }
          .w {
            stroke-dasharray: 90;
            stroke-dashoffset: 0;
            animation: wAnimation 2s ease-in-out;
          }

          @keyframes mAnimation {
            0% {
              stroke-dashoffset: 90;
            }

            100% {
              stroke-dashoffset: 0;
            }
          }
          @keyframes wAnimation {
            0% {
              stroke-dashoffset: 90;
            }

            50% {
              stroke-dashoffset: 90;
            }

            100% {
              stroke-dashoffset: 0;
            }
          }
        `
      : null}
`

const Logo = ({ variant = "white", withAnimation = false, className }) => {
  const variants = {
    white: "#fff",
    black: "#000",
    color: GlobalColors.primary,
  }

  return (
    <>
      <StyledLogo
        className={className}
        withAnimation={withAnimation}
        width="120"
        height="40"
        viewBox="0 0 120 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            className="m"
            d="M1.5 18.5L1.5 1H4.5L12 9.25L19.5 17.5H21L36 1H38.5V18.5"
            stroke={variants[variant]}
            strokeWidth="2"
          />
          <path
            className="w"
            d="M1.5 19L1.5 36.5H4.5L12 28.25L19.5 20H21L36 36.5H38.5V19"
            stroke={variants[variant]}
            strokeWidth="2"
          />
        </g>
        <path
          d="M57.4464 13.3389C57.8478 11.9 58.345 10.3564 58.9383 8.70793L62.1003 -0.0350342H66.8089V18.2432H63.4554V8.98117C63.4554 7.62427 63.5102 6.0488 63.6196 4.2545H63.4965C63.3412 4.77364 63.1587 5.35656 62.949 6.00307C62.7389 6.64985 62.5199 7.28717 62.292 7.91564L58.5004 18.2433H56.3377L52.5462 7.91564C52.318 7.28726 52.099 6.64985 51.8892 6.00307C51.6791 5.35656 51.4967 4.77355 51.3417 4.2545H51.2185C51.328 5.93947 51.3828 7.51047 51.3828 8.9675V18.2432H48.0291V-0.0350342H52.7103L55.8722 8.66693C56.4013 10.1149 56.8988 11.6723 57.3642 13.3389H57.4464Z"
          fill={variant === "black" ? variants[variant] : variants.white}
        />
        <path
          d="M77.91 7.83365L82.7829 -0.0350342H86.506L79.5662 10.88V18.2432H76.2126V10.88L69.2729 -0.0350342H72.996L77.869 7.83365H77.91Z"
          fill={variant === "black" ? variants[variant] : variants.white}
        />
        <path
          d="M80.4403 35.2076H73.5433L71.7493 39.866H68.1768L75.4451 21.5877H78.5113L85.7796 39.866H82.2344L80.4403 35.2076ZM76.9644 25.9865C76.324 27.8986 75.7528 29.4965 75.2509 30.7804L74.6248 32.4072H79.3588L78.7327 30.7804C78.2364 29.4852 77.6651 27.8873 77.0192 25.9865H76.9644Z"
          fill={variant === "black" ? variants[variant] : variants.white}
        />
        <path
          d="M97.3868 34.6202C97.3868 34.1558 97.2726 33.7801 97.0446 33.4932C96.8164 33.2064 96.5061 32.972 96.1139 32.7897C95.7214 32.6076 95.281 32.4597 94.793 32.3457C94.3047 32.2319 93.7914 32.1203 93.2531 32.011C92.5413 31.8562 91.8614 31.6718 91.2136 31.4577C90.5655 31.2439 89.9953 30.9478 89.5026 30.5698C89.0098 30.1919 88.6174 29.7115 88.3254 29.1285C88.0333 28.5458 87.8874 27.8171 87.8874 26.9428C87.8874 25.9776 88.0562 25.1375 88.394 24.4224C88.7315 23.7075 89.1899 23.1156 89.7696 22.6464C90.349 22.1775 91.0289 21.8268 91.8091 21.5946C92.5892 21.3623 93.4675 21.2462 94.444 21.2462C95.4204 21.2462 96.4014 21.3418 97.3869 21.5331C98.3724 21.7243 99.2211 21.9702 99.9329 22.2708V25.1396C98.9837 24.7662 98.0734 24.4953 97.2022 24.3268C96.3306 24.1584 95.5117 24.074 94.7452 24.074C93.641 24.074 92.79 24.2745 92.1924 24.6751C91.5946 25.076 91.2958 25.677 91.2958 26.4782C91.2958 26.9427 91.3938 27.3252 91.5901 27.6258C91.7862 27.9263 92.0532 28.1745 92.3909 28.3703C92.7284 28.5663 93.1164 28.7234 93.5544 28.8416C93.9924 28.9601 94.4578 29.0694 94.9505 29.1695C95.726 29.3334 96.4675 29.5226 97.1748 29.7364C97.882 29.9505 98.5094 30.2487 99.0569 30.6312C99.6044 31.0137 100.038 31.5123 100.357 32.127C100.677 32.7417 100.836 33.5273 100.836 34.4835C100.836 35.4671 100.654 36.3187 100.289 37.0381C99.9238 37.7576 99.4059 38.3519 98.7353 38.8208C98.0645 39.29 97.2501 39.6384 96.2919 39.8659C95.3338 40.0934 94.2843 40.2073 93.1437 40.2073C92.149 40.2073 91.1704 40.1094 90.2076 39.9136C89.2448 39.7179 88.4441 39.4423 87.8054 39.0871V36.2593C88.7088 36.651 89.5893 36.9355 90.4473 37.1131C91.3049 37.2907 92.1901 37.3795 93.1027 37.3795C93.6318 37.3795 94.152 37.3364 94.6631 37.2498C95.174 37.1633 95.6327 37.0154 96.0388 36.8058C96.4447 36.5964 96.7711 36.3119 97.0174 35.952C97.2636 35.5925 97.3868 35.1485 97.3868 34.6202Z"
          fill={variant === "black" ? variants[variant] : variants.white}
        />
        <path
          d="M107.913 29.1148H116.399V21.5877H119.753V39.866H116.399V31.9154H107.913V39.866H104.559V21.5878H107.913V29.1148Z"
          fill={variant === "black" ? variants[variant] : variants.white}
        />
        <path
          d="M57.3868 26.4672C56.9854 27.9061 56.4882 29.4498 55.8949 31.0982L52.733 39.8412H48.0243V21.563H51.3778V30.8251C51.3778 32.182 51.3231 33.7574 51.2136 35.5517H51.3368C51.492 35.0326 51.6745 34.4497 51.8842 33.8032C52.0943 33.1564 52.3133 32.5191 52.5412 31.8906L56.3328 21.563H58.4955L62.287 31.8906C62.5153 32.519 62.7343 33.1564 62.9441 33.8032C63.1541 34.4497 63.3365 35.0327 63.4915 35.5517H63.6147C63.5052 33.8668 63.4504 32.2958 63.4504 30.8387V21.563H66.804V39.8412H62.1227L58.9609 31.1392C58.4318 29.6912 57.9342 28.1339 57.4688 26.4672H57.3868Z"
          fill={variant === "black" ? variants[variant] : variants.white}
        />
      </StyledLogo>
    </>
  )
}

export default Logo
