import React, { Component } from "react"
import styled from "styled-components"
import GlobalStyles from "../assets/styles/GlobalStyles"
import Header from "../components/Organisms/Header/Header"
import Footer from "../components/Organisms/Footer/Footer"
import { WithIntl } from "../components/Atoms/withTranslate"
import { Location } from "@reach/router"

const MainLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const StyledContentWrapper = styled.div`
  flex: 1;
  width: 100%;
`

class MainLayout extends Component {
  render() {
    const { children, language } = this.props
    return (
      <>
        <Location>
          {({ location }) => {
            return (<>
              <Header language={language} location={location}/>
              <MainLayoutWrapper>
                <GlobalStyles language={language}/>
                <StyledContentWrapper>
                  {children}
                </StyledContentWrapper>
                <Footer language={language}/>
              </MainLayoutWrapper>
            </>)
          }
          }
        </Location>
      </>
    )
  }
}

export default WithIntl()(MainLayout)
