import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../assets/styles/GlobalVariables"

const StyledDropdown = styled.div`
  padding: 32px;
  background-color: ${GlobalColors.white};
  position: absolute;
  box-shadow: 10px 20px 40px -10px rgba(0, 0, 0, 0.1);
  min-width: 220px;
  border: 1px solid ${GlobalColors.gray300};
  border-radius: 4px;
  z-index: 999;

  ::before {
    content: "";
    display: block;
    background-color: ${GlobalColors.white};
    width: 24px;
    height: 24px;
    position: absolute;
    top: -12px;
    transform: rotate(45deg);
    border-top: 1px solid ${GlobalColors.gray300};
    border-left: 1px solid ${GlobalColors.gray300};
    border-radius: 4px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      font-family: Teko;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 1.8rem;
      margin-bottom: 16px;
      line-height: 1.8rem;
      cursor: pointer;
      color: ${GlobalColors.black};

      a {
        color: inherit;
      }

      &.smaller-font {
        font-size: 1.6rem;
      }

      :hover {
        color: ${GlobalColors.primary};
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

const Dropdown = ({ children, className }) => (
  <StyledDropdown className={className}>{children}</StyledDropdown>
)

export default Dropdown
