import React, { Component } from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import Logo from "../../Atoms/Logo"
import Navigation from "../../Molecules/Navigation/Navigation"
import LocalizedLink from "../../Atoms/LocalizedLink"

const StyledHeader = styled.div`
  background-color: ${GlobalColors.primary};
  transform: ${({ isHidden }) =>
    isHidden ? "translateY(-100%)" : "translateY(0)"};
  transition: transform 0.6s ease-in-out;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999999;
`

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  
  @media screen and (max-width: 1160px) {
      padding: 0 20px !important;
  }
`

class Header extends Component {
  state = {
    prevScrollPos: typeof window !== `undefined` ? window.pageYOffset : 0,
    visible: true,
  }

  handleScroll = () => {
    const { prevScrollPos } = this.state

    const currentScrollPos = window.pageYOffset
    const visible = prevScrollPos > currentScrollPos

    this.setState({
      prevScrollPos: currentScrollPos,
      visible: currentScrollPos <= 100 || visible,
    })
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  render() {
    const { language, location } = this.props
    return (
      <StyledHeader isHidden={!this.state.visible}>
        <StyledHeaderContainer className="container">
          <LocalizedLink to="/" language={language}>
            <Logo variant="white" withAnimation />
          </LocalizedLink>
          <Navigation language={language} location={location} isHeaderHidden={!this.state.visible} />
        </StyledHeaderContainer>
      </StyledHeader>
    )
  }
}

export default Header
