import React, { Component } from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import arrowDown from "../../../assets/images/icons/Arrow Down.svg"
import arrowRight from "../../../assets/images/icons/arrow right.svg"
import ue from "../../../assets/images/icons/ue.svg"
import mobileMenuIcon from "../../../assets/images/icons/Menu.svg"
import { localize } from "../../Atoms/Language"
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher"
import Dropdown from "../../Atoms/Dropdown"
import LocalizedLink from "../../Atoms/LocalizedLink"

const StyledNav = styled.nav`
  display: flex;
  width: 100%;
  color: ${GlobalColors.white};
  align-items: center;
  @media screen and (max-width: 1100px) {
    display: ${({ isHidden }) => (isHidden ? "none" : "block")};
    background-color: ${GlobalColors.black};
    position: absolute;
    top: 100px;
    left: 0;
    padding: 0 0 20px;
  }
`

const Spacer = styled.div`
  flex: 1;
  flex-shrink: 0;
`

const StyledButton = styled(LocalizedLink)`
  @media screen and (max-width: 1100px) {
    width: calc(100% - 40px);
    margin: 0 20px;
    height: 48px;
    &::after {
      display: inline-block;
      position: relative;
      top: 4px;
      left: 16px;
    }
  }
`

const StyledNavList = styled.ul`
  list-style: none;
  padding: 0 50px;
  @media screen and (max-width: 1100px) {
    padding: 0;
  }
`

const StyledUeElement = styled.li`
  display: inline-block;
  position: relative;
  left: 20px;
  top: 5px;

  @media screen and (max-width: 1100px) {
    display: block;
    width: 100%;
    text-align: right;
    padding: 0 50px;
  }
`

const StyledNavElement = styled.li`
  color: ${GlobalColors.white};
  display: inline-block;
  font-family: Teko;
  //margin-right: 48px;
  font-size: ${({ language }) => (language === "ru" ? "1.6rem" : "1.8rem")};
  margin-right: ${({ language }) => language === "ru" ? "29px" : "48px"};
  line-height: 1;
  text-transform: uppercase;
  position: relative;

  @media screen and (max-width: 1100px) {
    display: block;
    padding: 15px 0;
    margin: 0;
  }

  @media screen and (min-width: 1025px) {
    height: 100px;
    line-height: 100px;
    &::after {
      content: "";
      width: 100%;
      height: 2px;
      background-color: ${GlobalColors.white};
      position: absolute;
      top: 60%;
      left: 0;
      transform: scaleX(0);
      transition: transform 0.3s ease-in-out;
    }

    &:hover {
      &::after {
        transform: scaleX(1);
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  & > a {
    @media screen and (max-width: 1100px) {
      width: 100%;
      height: 100%;
      display: block;
      padding: 0 30px;
    }
  }
`

const StyledNavElementWithDropdown = styled(StyledNavElement)`
  padding-right: 30px;
  background-image: url(${arrowDown});
  background-repeat: no-repeat;
  background-position: right center;

  &::after {
    width: calc(100% - 30px);
  }

  @media screen and (min-width: 1025px) {
    :hover .dropdown {
      display: block;
      opacity: 1;
      animation: dropdown 0.3s ease-in-out;
    }
  }

  @media screen and (max-width: 1100px) {
    .dropdown {
      left: 0;
      position: relative;
      width: 100%;
      box-shadow: none;
      min-width: 0;
      border: none;
      border-radius: 0;
      margin-top: 20px;
      display: ${({ showDropdown }) => (showDropdown ? "block" : "none")};

      ::before {
        content: none;
      }
    }
  }
`

const StyledDropdown = styled(Dropdown)`
  position: absolute;
  left: -20px;
  display: none;
  @media screen and (max-width: 1100px) {
    position: relative;
    left: 0;
  }
`

const StyledToggleMenuButton = styled.button`
  width: 30px;
  height: 40px;
  background-image: url(${mobileMenuIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
  display: none;

  @media screen and (max-width: 1100px) {
    display: block;
  }
`

class Navigation extends Component {
  state = {
    isHidden: true,
    showDropdown: true,
  }

  toggleNavigation = () => {
    this.setState(state => ({ isHidden: !state.isHidden }))
  }

  toggleDropdownMenu = () => {
    this.setState(state => ({ showDropdown: !state.showDropdown }))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ isHidden: true })
    }
  }

  render() {
    const { language, location } = this.props

    return (
      <>
        <StyledNav isHidden={this.state.isHidden}>
          <StyledNavList>
            <StyledNavElement language={language}>
              <LocalizedLink to="/o-nas" language={language}>
                {localize("1")}
              </LocalizedLink>
            </StyledNavElement>
            <StyledNavElementWithDropdown language={language}
              onClick={this.toggleDropdownMenu}
              showDropdown={this.state.showDropdown}
            >
              <LocalizedLink to="/myjnie-bezdotykowe" language={language}>
                {localize("2")}
              </LocalizedLink>
              <StyledDropdown className="dropdown">
                <ul>
                  <li>
                    <LocalizedLink to="/myjnie-kontenerowe" language={language}>
                      {localize("3")}
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/myjnie-modulowe" language={language}>
                      {localize("4")}
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/rodzaje-wiat" language={language}>
                      {localize("5")}
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/chemia" language={language}>
                      {localize("6")}
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/akcesoria" language={language}>
                      {localize("7")}
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/czesci-do-myjni" language={language}>
                      {localize("8")}
                    </LocalizedLink>
                  </li>
                </ul>
              </StyledDropdown>
            </StyledNavElementWithDropdown>
            <StyledNavElement language={language}>
              <LocalizedLink to="/serwis" language={language}>
                {localize("9")}
              </LocalizedLink>
            </StyledNavElement>
            <StyledNavElement language={language}>
              <LocalizedLink to="/dla-inwestora" language={language}>
                {localize("10")}
              </LocalizedLink>
            </StyledNavElement>
            <StyledNavElement language={language}>
              <LocalizedLink to="/kontakt" language={language}>
                {localize("11")}
              </LocalizedLink>
            </StyledNavElement>
          </StyledNavList>
          <Spacer/>
          <LanguageSwitcher language={language} location={location}/>
          <StyledButton
            isButton
            to="/kalkulator-rentownosci"
            language={language}
            icon={arrowRight}
            className="revert-button"
          >
            {localize("12")}
          </StyledButton>
        </StyledNav>
        <StyledUeElement>
          <LocalizedLink to="/ue" language={language}>
            <img width={70} src={ue} alt="Flaga Uni Europejskiej"/>
          </LocalizedLink>
        </StyledUeElement>
        <StyledToggleMenuButton onClick={this.toggleNavigation}/>
      </>
    )
  }
}

export default Navigation
