export const GlobalColors = {
  primary: "#E71527",
  secondary: "#FFE70F",
  black: "#000",
  gray600: "#667180",
  gray500: "#8D97A6",
  gray300: "#D3DBE6",
  white: "#FFFFFF",
  success: "#65C539"
}
