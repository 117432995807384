import React, { Component } from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import Logo from "../../Atoms/Logo"
import phoneIcon from "../../../assets/images/icons/Phone.svg"
import facebookIcon from "../../../assets/images/icons/Facebook.svg"
import emailIcon from "../../../assets/images/icons/Email.svg"
import LocalizedLink from "../../Atoms/LocalizedLink"
import { localize } from "../../Atoms/Language"
import Cookies from "../Cookies/Cookies"

const StyledFooter = styled.footer`
  width: 100%;
  background-color: ${GlobalColors.black};
  padding: 50px 0 0;
  color: ${GlobalColors.white};

  @media screen and (max-width: 960px) {
    padding: 50px 20px;
  }
`

const StyledFooterContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`

const StyledUnderFooterContent = styled(StyledFooterContent)`
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 48px !important;
  padding: 32px 0;
  font-size: 1.4rem;

  span {
    color: rgba(255, 255, 255, 0.5);
  }

  a {
    color: ${GlobalColors.white};
    text-decoration: none;
  }
`

const StyledContentContainer = styled.div`
  flex: 1;
  flex-shrink: 0;
  min-width: 200px;
  padding: 0 10px;

  :first-child ul:first-of-type {
    margin-top: 40px;
    li a {
      text-decoration: underline;
      line-height: 1;
    }
  }

  h4 {
    font-size: 1.8rem;
    margin-top: 10px;
    text-transform: uppercase;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      font-family: "Inter";
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2.2rem;

      a {
        display: inline-block;
        padding: 4px 0;
        color: inherit;
        text-decoration: none;
      }
    }
  }
  &:last-of-type li {
    padding: 4px 0 4px 30px;
    background-position: left center;
    background-repeat: no-repeat;

    &:first-child {
      background-image: url(${phoneIcon});
    }

    &:nth-child(2) {
      background-image: url(${emailIcon});
    }
    &:last-child {
      background-image: url(${facebookIcon});
    }
  }
`

class Footer extends Component {

  state = {
    showCookiesInfo: typeof window !== 'undefined' ? localStorage.getItem("cookiesAccepted") !== "true" : null,
  }

  render() {
    const { language } = this.props

    return (
      <StyledFooter>
        <StyledFooterContent className="container">
          <StyledContentContainer>
            <Logo variant="color"/>
            <ul>
              <li>My Wash Sp. z o.o. Sp. K.</li>
              <li>
                <LocalizedLink to="/myjnie-bezdotykowe" language={language}>{localize("13")}</LocalizedLink>
              </li>
              <li>{localize("14")}</li>
              <li>{localize("15")}</li>
            </ul>
            <ul>
              <li>{localize("16")}</li>
              <li>{localize("17")}</li>
              <li>{localize("18")}</li>
            </ul>
          </StyledContentContainer>
          <StyledContentContainer>
            <h4>{localize("1")}</h4>
            <ul>
              <li>
                <LocalizedLink to="/serwis" language={language}>{localize("9")}</LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/dla-inwestora" language={language}>{localize("10")}</LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/kalkulator-rentownosci" language={language}>{localize("12")}</LocalizedLink>
              </li>
              <li>
                <a
                  href="http://forum.mywash.pl/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >{localize("20")}</a>
              </li>
            </ul>
          </StyledContentContainer>
          <StyledContentContainer>
            <h4>{localize("2")}</h4>
            <ul>
              <li>
                <LocalizedLink to="/myjnie-kontenerowe" language={language}>{localize("3")}</LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/myjnie-modulowe" language={language}>{localize("4")}</LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/rodzaje-wiat" language={language}>{localize("5")}</LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/chemia" language={language}>{localize("6")}</LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/akcesoria" language={language}>{localize("7")}</LocalizedLink>
              </li>
              <li>
                <LocalizedLink to="/czesci-do-myjni" language={language}>{localize("8")}</LocalizedLink>
              </li>
            </ul>
          </StyledContentContainer>
          <StyledContentContainer>
            <h4>{localize("11")}</h4>
            <ul>
              <li><a href="tel:+48606905890">+48 606 905 890</a></li>
              <li><a href="mailto:biuro@mywash.pl">biuro@mywash.pl</a></li>
              <li><a href="https://www.facebook.com/mywashpl/">/mywashpl</a></li>
            </ul>
          </StyledContentContainer>
        </StyledFooterContent>
        <StyledUnderFooterContent className="container">
      <span>
        © Copyright 2021 My Wash Sp. z o.o. Sp. K. All right reserved.
      </span>
          <LocalizedLink to="/polityka-prywatnosci" language={language}>{localize("21")}</LocalizedLink>
        </StyledUnderFooterContent>
        {this.state.showCookiesInfo && <Cookies close={() => this.setState({ showCookiesInfo: false })}/>}
      </StyledFooter>
    )
  }
}

export default Footer
