import { createGlobalStyle } from "styled-components"
import { GlobalColors } from "./GlobalVariables"

const GlobalStyles = createGlobalStyle`
html {
    box-sizing: border-box;
    font-size: ${({ language }) => (language === 'ru' ? '48%' : '62.5%')};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    background-color: ${GlobalColors.black};
    padding-top: 100px;
    
    @media screen and (max-width: 600px) {
      font-size: 50%;
    }
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
    outline: none;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: ${({ language }) => (language === 'ru' ? "Roboto" : 'Inter')};
    font-weight: 500;
    font-size: 1.6rem; 
    line-height: 2.8rem;
    background-color: ${GlobalColors.white};
  }
  
  ul {
    padding: 0;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: ${({ language }) => (language === 'ru' ? "Roboto Condensed" : "Teko")};
    font-weight: ${({ language }) => (language === 'ru' ? 700 : 600)};
    line-height: 1;
    text-transform: uppercase;
    margin: 0 0 24px;
    overflow-wrap: break-word;
  }
    
  .header1 {
    font-size: 7.2rem;
  };
  .header2 {
    font-size: 6.4rem;
  };
  .header3 {
    font-size: 4.8rem;
  }
  .header4 {
    font-size: 3.2rem;
  }
  .header5 {
    font-size: 2.4rem;
  }
  .header6 {
    font-size: 1.8rem;
  }
  
  .body {
    font-family: Inter;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: -0.2px;
    
    &-large {
      font-size: 2.2rem;
      line-height: 3.2rem;
    }
    
    &-small {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }
  }
  
  button {
    padding: 0;
    cursor: pointer;
    font-family: 'Teko';
  }
  
  p {
    font-size: 1.8rem;
    font-family: 'Inter';
    line-height: 2.8rem;
    font-weight: 500;
    
    .large {
      font-size: 2.2rem;
      line-height: 3.2rem;
    }
  }
  
  label {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Teko';
    line-height: 2rem;
    margin: 8px 0;
    display: block;
  }
  
  .label {
    font-size: 1.8rem;
    font-weight: 700;
    font-family: Inter;
    line-height: 2rem;
    
    &-large {
      font-size: 2.2rem;
      line-height: 2.4rem;
    }
  }
  
  .container {
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
    
    @media screen and (max-width: 1160px) {
      padding: 0 50px;
    }
    
    @media screen and (max-width: 550px) {
      padding: 0 10px;
    }
  }
  
  @media screen and (max-width: 1160px) {
    .mobile-container {
      max-width: 540px;
      width: 100%;
      margin: 0 auto;
      padding: 0 20px;
    }
  }
  
  .no-margin {
    margin: 0;
  }
  
  .gray600 {
    color: ${GlobalColors.gray600};
  }
  
  .mt8 {
    margin-top: 80px;
  }
  
  .mt16 {
    margin-top: 80px;
  }
  
  .mb8 {
    margin-bottom: 80px;
  }
  
  .mb16 {
    margin-bottom: 80px;
  }
  
  .pt8 {
    padding-top: 80px;
  }
  
  .pt16 {
    padding-top: 80px;
  }
  
  .pb8 {
    padding-bottom: 80px;
  }
  
  .pb16 {
    padding-bottom: 80px;
  }
  
  @media screen and (max-width: 1024px){
    .mt8 {
      margin-top: 40px;
    }
  
    .mt16 {
      margin-top: 80px;
    }
    
    .mb8 {
      margin-bottom: 40px;
    }
    
    .mb16 {
      margin-bottom: 80px;
    }
    .pt8 {
      padding-top: 40px;
    }
  
    .pt16 {
      padding-top: 80px;
    }
    
    .pb8 {
      padding-bottom: 40px;
    }
    
    .pb16 {
      padding-bottom: 80px;
    }
  }

  .nowrap {
    flex-wrap: nowrap;
  }
  
  .revert-button {
    background-color: ${GlobalColors.white};
    color: ${GlobalColors.primary};
  }
  
  .strong {
    font-weight: 700;
  }
  
  // ANIMATIONS
  
    @keyframes dropdown {
    from {
      display: none;
      opacity: 0;
    }
    
    to {
      display: block;
      opacity: 1;
    }
  }
  
  @keyframes slide-from-left {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }  
    
    to {
      opacity: 1;
      transform: translateX(0);      
    }
  }
`

export default GlobalStyles
