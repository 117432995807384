import React from "react"
import styled, { css } from "styled-components"
import { GlobalColors } from "../../assets/styles/GlobalVariables"

export const StyledButton = styled.button`
  background-color: ${GlobalColors.primary};
  font-size: 1.8rem;
  color: ${GlobalColors.white};
  padding: 10px 20px;
  height: 40px;
  border-radius: 4px;
  text-transform: uppercase;
  border: none;
  position: relative;
  
  a {
    color: inherit;
    text-decoration: none;
  }

  ${({ isBig }) =>
  isBig
    ? css`
          padding: 0 32px;
          font-size: 2.4rem;
          height: 56px;
        `
    : null}

  ${({ isRevert }) =>
  isRevert
    ? css`
          background-color: ${GlobalColors.white};
          color: ${GlobalColors.primary};
        `
    : null}

  ${({ icon }) =>
  icon
    ? css`
          padding-right: 40px;
          &::after {
            content: "";
            width: 16px;
            height: 16px;
            background-image: url(${icon});
            background-repeat: no-repeat;
            position: absolute;
            right: 16px;
            top: 13px;
            transition: transform 0.3s ease-in-out;
          }

          &:hover::after {
            transform: translateX(5px);
          }
        `
    : null}
`

const Button = ({ children, isBig, isRevert, icon, className, props, type = 'submit' }) => (
  <StyledButton
    className={className}
    isBig={isBig}
    isRevert={isRevert}
    icon={icon}
    type={type}
    {...props}>
    {children}
  </StyledButton>
)

export default Button
