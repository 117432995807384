import React from "react"
import { I18nextProvider } from "react-i18next"
import i18next from "./Language"

// HOC that pre renders a page with the translated language (during build)
// Without this HOC the page will be translated on the client side dynamically
export const WithIntl = () => WrappedComponent => {
  const WrapWithIntl = ({ pageContext, children, data }, ...props) => {
    const addResources = (pc, language) => {
      // passed down from createPages(...) in gatsby-node.js
      if (pc && pc.localeResources) {
        if (!i18next.hasResourceBundle(language, `translation`)) {
          // We only want to add the es.json locales for our /es pages
          i18next.addResourceBundle(language, `translation`, {
            ...pc.localeResources,
          })
        }
      }
    }

    if (pageContext) {
      const current_language = pageContext.locale
      if ((current_language && current_language !== i18next.language) || !i18next.getDataByLanguage()) {
        addResources(pageContext, current_language)
        i18next.changeLanguage(current_language)
      }
    }
    return (
      <I18nextProvider i18n={i18next}>
        <WrappedComponent {...props} data={data} language={i18next.language}>
          {children}
        </WrappedComponent>
      </I18nextProvider>
    )
  }
  return WrapWithIntl
}
