// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-akcesoria-js": () => import("./../src/pages/akcesoria.js" /* webpackChunkName: "component---src-pages-akcesoria-js" */),
  "component---src-pages-chemia-js": () => import("./../src/pages/chemia.js" /* webpackChunkName: "component---src-pages-chemia-js" */),
  "component---src-pages-czesci-do-myjni-js": () => import("./../src/pages/czesci-do-myjni.js" /* webpackChunkName: "component---src-pages-czesci-do-myjni-js" */),
  "component---src-pages-dla-inwestora-js": () => import("./../src/pages/dla-inwestora.js" /* webpackChunkName: "component---src-pages-dla-inwestora-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kalkulator-rentownosci-js": () => import("./../src/pages/kalkulator-rentownosci.js" /* webpackChunkName: "component---src-pages-kalkulator-rentownosci-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-myjnie-bezdotykowe-js": () => import("./../src/pages/myjnie-bezdotykowe.js" /* webpackChunkName: "component---src-pages-myjnie-bezdotykowe-js" */),
  "component---src-pages-myjnie-kontenerowe-js": () => import("./../src/pages/myjnie-kontenerowe.js" /* webpackChunkName: "component---src-pages-myjnie-kontenerowe-js" */),
  "component---src-pages-myjnie-modulowe-js": () => import("./../src/pages/myjnie-modulowe.js" /* webpackChunkName: "component---src-pages-myjnie-modulowe-js" */),
  "component---src-pages-o-nas-js": () => import("./../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-realizacje-bialystok-1-js": () => import("./../src/pages/realizacje/bialystok-1.js" /* webpackChunkName: "component---src-pages-realizacje-bialystok-1-js" */),
  "component---src-pages-realizacje-bialystok-2-js": () => import("./../src/pages/realizacje/bialystok-2.js" /* webpackChunkName: "component---src-pages-realizacje-bialystok-2-js" */),
  "component---src-pages-realizacje-czyzew-js": () => import("./../src/pages/realizacje/czyzew.js" /* webpackChunkName: "component---src-pages-realizacje-czyzew-js" */),
  "component---src-pages-realizacje-gizycko-js": () => import("./../src/pages/realizacje/gizycko.js" /* webpackChunkName: "component---src-pages-realizacje-gizycko-js" */),
  "component---src-pages-realizacje-lomza-js": () => import("./../src/pages/realizacje/lomza.js" /* webpackChunkName: "component---src-pages-realizacje-lomza-js" */),
  "component---src-pages-realizacje-mariampol-js": () => import("./../src/pages/realizacje/mariampol.js" /* webpackChunkName: "component---src-pages-realizacje-mariampol-js" */),
  "component---src-pages-realizacje-preili-js": () => import("./../src/pages/realizacje/preili.js" /* webpackChunkName: "component---src-pages-realizacje-preili-js" */),
  "component---src-pages-realizacje-ustka-js": () => import("./../src/pages/realizacje/ustka.js" /* webpackChunkName: "component---src-pages-realizacje-ustka-js" */),
  "component---src-pages-realizacje-zambrow-js": () => import("./../src/pages/realizacje/zambrow.js" /* webpackChunkName: "component---src-pages-realizacje-zambrow-js" */),
  "component---src-pages-rodzaje-wiat-js": () => import("./../src/pages/rodzaje-wiat.js" /* webpackChunkName: "component---src-pages-rodzaje-wiat-js" */),
  "component---src-pages-serwis-js": () => import("./../src/pages/serwis.js" /* webpackChunkName: "component---src-pages-serwis-js" */),
  "component---src-pages-ue-js": () => import("./../src/pages/ue.js" /* webpackChunkName: "component---src-pages-ue-js" */)
}

