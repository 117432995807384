import React, { Component } from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import Logo from "../../Atoms/Logo"
import { StyledButton } from "../../Atoms/Button"
import { Link } from "gatsby"

const StyledWrapper = styled.div`
  display: block;
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: ${GlobalColors.primary};
  width: 630px;
  height: 210px;
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px 40px;
  z-index: 999999;
  animation: ${({ open }) => (open ? "open" : "close")} 1s ease-in-out;

  @media screen and (max-width: 720px) {
    bottom: 0;
    width: 100vw;
    right: 0;
    height: 170px;
    
   @keyframes close {
    0% {
      bottom: 0;
    }

    100% {
      bottom: -399px;
    }
  }

  @keyframes open {
    0% {
      bottom: -250px;
    }

    100% {
      bottom: 0;
    }
  }
  }
  
  @media screen and (min-width: 721px) {

  @keyframes close {
    0% {
      bottom: 40px;
    }

    40% {
      bottom: 60px;
    }

    100% {
      bottom: -999px;
    }
  }

  @keyframes open {
    0% {
      bottom: -250px;
    }

    100% {
      bottom: 40px;
    }
  }
  }
`

const StyledLogo = styled(Logo)`
  width: 258px;
  height: 86px;

  @media screen and (max-width: 720px) {
    display: none;
  }
`

const StyledBannerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StyledLink = styled(Link)`
  font-weight: 700;
  color: inherit;
  text-decoration: none;
`

const MyStyledButton = styled(StyledButton)`
  @media screen and (max-width: 720px) {
    position: absolute;
    right: 40px;
    bottom: 20px;
  }
`

class Cookies extends Component {
  state = {
    open: true,
  }

  acceptCookies = close => {
    if (typeof window !== "undefined") {
      localStorage.setItem("cookiesAccepted", "true")
      this.setState({ open: false })
      setTimeout(() => close(), 900)
    }
  }

  render() {
    const { close } = this.props
    return (
      <StyledWrapper open={this.state.open}>
        <StyledBannerHeader>
          <StyledLogo/>
          <MyStyledButton
            isRevert
            type="button"
            onClick={() => this.acceptCookies(close)}
          >
            Akceptuje
          </MyStyledButton>
        </StyledBannerHeader>
        <p className="body-small">
          Nasz strona korzysta z plików cookies w celu zbierania informacji
          pozwalających poprawić jakość naszych usług. Dokładne informacje na
          ten temat znajdziesz pod adresem:
          <StyledLink to="/polityka-prywatnosci">
            {" "}
            mywash.pl/polityka-prywatności
          </StyledLink>
          .
        </p>
      </StyledWrapper>
    )
  }
}

export default Cookies
