module.exports = {
  pl: {
    is_default: true,
    path: "pl",
    display_name: "Polski",
  },
  ru: {
    is_default: false,
    path: "ru",
    display_name: "Русский",
  },
  lt: {
    is_default: false,
    path: "lt",
    display_name: "Lietuvių",
  },
  lv: {
    is_default: false,
    path: "lv",
    display_name: "Latvijas",
  },
  en: {
    is_default: false,
    path: "en",
    display_name: "English",
  },
  de: {
    is_default: false,
    path: "de",
    display_name: "Deutsch",
  },
}
